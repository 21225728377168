import Component404 from '~/components/404';
import { NextSeo } from 'next-seo';
import { sentryCaptureException } from '~/errors/sentry';
import { useEffect } from 'react';
import { useTranslate } from '~/i18n';

const Page404 = () => {
   const { translate } = useTranslate();

   useEffect(() => {
      sentryCaptureException(new Error('Page not found'));
   }, []);

   return (
      <div>
         <NextSeo title={translate({ id: 'common:404' })} nofollow noindex />
         <Component404 />
      </div>
   );
};
Page404.transparentNavbar = true;
export default Page404;
