import { Translate, NextLink } from '~/i18n';
import Container from '~/components/common/container';
const Component404 = () => {
   return (
      <div
         className="flex flex-1 h-full min-h-[640px] bg-center bg-cover"
         style={{ backgroundImage: 'url("/images/sand.jpeg")' }}>
         <style jsx global>{`
            main {
               display: flex;
               flex-direction: column;
            }
         `}</style>
         <Container>
            <span className="block text-2xl text-white mt-72">
               <Translate id="common:404" />
            </span>
            <span className="block mt-2 text-6xl font-medium text-white font-saudiSerif">
               Error 404
            </span>
            <div>
               <NextLink
                  href=""
                  className="inline-block w-48 py-2 mt-8 text-center text-white border rounded-full border-sta-primary bg-sta-primary">
                  <Translate id="common:home" />
               </NextLink>
            </div>
         </Container>
      </div>
   );
};
export default Component404;
